
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import DefaultController from "../../../../defaultController";
import UserEntity from "../../../../user/entity";
import TeamsSettingsUserOverviewShowVacationDayAllowance from "./show/vacation_day_allowances";
import TeamsSettingsUserOverviewShowTimeEntryProfileMaps from "./show/time_entry_profiles";

export default class TeamsSettingsUserOverviewShow extends DefaultController {
    async init() {
        this.entity = "users";
        await super.init();
        this.childs = {
            vacation_day_allowance: new TeamsSettingsUserOverviewShowVacationDayAllowance(this),
            time_entry_profile: new TeamsSettingsUserOverviewShowTimeEntryProfileMaps(this)
        }
    }

    protected getEntityData(elem: any) {
        return UserEntity.getEntityData(elem)
    }
    bindListeners() {
    }
}